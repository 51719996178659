import React from 'react';
import { color } from '../../../utils/style';
import IcomoonIcon from '../../shared/IcomoonIcon';

const MobileCallLink = () => {
  return (
    <a aria-label="Call" href={`tel:${process.env.PHONE_TO_CALL}`}>
      <IcomoonIcon className="contact-menu-icon" icon="tel" color={color.white} size={30} />
    </a>
  );
};

export default MobileCallLink;