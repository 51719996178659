import React from "react"

const Logo = ({
  width = "267",
  className = "",
  height = "53",
  viewBox = "0 0 150 40",
}) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      width={width}
      height={height}
      className={className}
      viewBox={viewBox}
      xmlSpace="preserve"
    >
      <linearGradient
        id="SVGID_1_"
        gradientUnits="userSpaceOnUse"
        x1="6.058"
        y1="24.774"
        x2="6.058"
        y2="12.608"
      >
        <stop offset=".056" stopColor="#fdd377" />
        <stop offset=".059" stopColor="#f6cc70" />
        <stop offset=".071" stopColor="#e6b959" />
        <stop offset=".086" stopColor="#dbab47" />
        <stop offset=".103" stopColor="#d3a23b" />
        <stop offset=".128" stopColor="#cf9d34" />
        <stop offset=".195" stopColor="#cd9c32" />
        <stop offset=".196" stopColor="#cd9b32" />
        <stop offset=".237" stopColor="#be8a29" />
        <stop offset=".28" stopColor="#b68124" />
        <stop offset=".329" stopColor="#b37e23" />
        <stop offset=".348" stopColor="#b98324" />
        <stop offset=".373" stopColor="#c89026" />
        <stop offset=".388" stopColor="#d59b27" />
        <stop offset=".498" stopColor="#ddad26" />
        <stop offset=".637" stopColor="#e5c122" />
        <stop offset=".676" stopColor="#e8c743" />
        <stop offset=".761" stopColor="#efd787" />
        <stop offset=".886" stopColor="#fcf8ec" />
        <stop offset=".902" stopColor="#fff" />
      </linearGradient>
      <linearGradient
        id="SVGID_2_"
        gradientUnits="userSpaceOnUse"
        x1="6.058"
        y1="24.824"
        x2="6.058"
        y2="12.558"
      >
        <stop offset=".041" stopColor="#190e10" />
        <stop offset=".054" stopColor="#2d211c" />
        <stop offset=".083" stopColor="#513d29" />
        <stop offset=".113" stopColor="#74562f" />
        <stop offset=".144" stopColor="#966e32" />
        <stop offset=".178" stopColor="#b38332" />
        <stop offset=".214" stopColor="#ca932f" />
        <stop offset=".255" stopColor="#d89d2d" />
        <stop offset=".311" stopColor="#dea12b" />
        <stop offset=".715" stopColor="#5c100d" />
        <stop offset=".747" stopColor="#611811" />
        <stop offset=".795" stopColor="#702b19" />
        <stop offset=".855" stopColor="#894927" />
        <stop offset=".924" stopColor="#b2753a" />
        <stop offset=".999" stopColor="#f2bd58" />
        <stop offset="1" stopColor="#f3bf58" />
      </linearGradient>
      <path
        d="M11.3 24.8H7.8v-.3c.5 0 .7-.2.7-.8v-4.4H3.7v4.4c0 .6.1.8.6.8v.3H.9v-.3c.5 0 .7-.2.7-.8v-10c0-.6-.2-.8-.7-.8v-.3h3.5v.3c-.5 0-.6.2-.6.8v3.7h4.7v-3.7c0-.6-.1-.8-.7-.8v-.3h3.5v.3c-.5 0-.7.2-.7.8v10c0 .6.2.8.7.8v.3z"
        fill="url(#SVGID_1_)"
        stroke="url(#SVGID_2_)"
        strokeWidth=".1"
        strokeMiterlimit="10"
      />
      <linearGradient
        id="SVGID_3_"
        gradientUnits="userSpaceOnUse"
        x1="17.779"
        y1="24.948"
        x2="17.779"
        y2="12.399"
      >
        <stop offset=".056" stopColor="#fdd377" />
        <stop offset=".059" stopColor="#f6cc70" />
        <stop offset=".071" stopColor="#e6b959" />
        <stop offset=".086" stopColor="#dbab47" />
        <stop offset=".103" stopColor="#d3a23b" />
        <stop offset=".128" stopColor="#cf9d34" />
        <stop offset=".195" stopColor="#cd9c32" />
        <stop offset=".196" stopColor="#cd9b32" />
        <stop offset=".237" stopColor="#be8a29" />
        <stop offset=".28" stopColor="#b68124" />
        <stop offset=".329" stopColor="#b37e23" />
        <stop offset=".348" stopColor="#b98324" />
        <stop offset=".373" stopColor="#c89026" />
        <stop offset=".388" stopColor="#d59b27" />
        <stop offset=".498" stopColor="#ddad26" />
        <stop offset=".637" stopColor="#e5c122" />
        <stop offset=".676" stopColor="#e8c743" />
        <stop offset=".761" stopColor="#efd787" />
        <stop offset=".886" stopColor="#fcf8ec" />
        <stop offset=".902" stopColor="#fff" />
      </linearGradient>
      <linearGradient
        id="SVGID_4_"
        gradientUnits="userSpaceOnUse"
        x1="17.779"
        y1="24.998"
        x2="17.779"
        y2="12.349"
      >
        <stop offset=".041" stopColor="#190e10" />
        <stop offset=".054" stopColor="#2d211c" />
        <stop offset=".083" stopColor="#513d29" />
        <stop offset=".113" stopColor="#74562f" />
        <stop offset=".144" stopColor="#966e32" />
        <stop offset=".178" stopColor="#b38332" />
        <stop offset=".214" stopColor="#ca932f" />
        <stop offset=".255" stopColor="#d89d2d" />
        <stop offset=".311" stopColor="#dea12b" />
        <stop offset=".715" stopColor="#5c100d" />
        <stop offset=".747" stopColor="#611811" />
        <stop offset=".795" stopColor="#702b19" />
        <stop offset=".855" stopColor="#894927" />
        <stop offset=".924" stopColor="#b2753a" />
        <stop offset=".999" stopColor="#f2bd58" />
        <stop offset="1" stopColor="#f3bf58" />
      </linearGradient>
      <path
        d="M17.8 24.9c-3.1 0-5.3-2.3-5.3-6.3 0-3.7 2.2-6.3 5.3-6.3 3.1 0 5.3 2.6 5.3 6.3 0 3.8-2 6.3-5.3 6.3zm0-10.6c-1.9 0-3.1 1.8-3.1 4.4 0 2.3 1.1 4.4 3.1 4.4s3.1-2.1 3.1-4.4c0-2.5-1.1-4.4-3.1-4.4z"
        fill="url(#SVGID_3_)"
        stroke="url(#SVGID_4_)"
        strokeWidth=".1"
        strokeMiterlimit="10"
      />
      <linearGradient
        id="SVGID_5_"
        gradientUnits="userSpaceOnUse"
        x1="28.432"
        y1="24.948"
        x2="28.432"
        y2="12.608"
      >
        <stop offset=".056" stopColor="#fdd377" />
        <stop offset=".059" stopColor="#f6cc70" />
        <stop offset=".071" stopColor="#e6b959" />
        <stop offset=".086" stopColor="#dbab47" />
        <stop offset=".103" stopColor="#d3a23b" />
        <stop offset=".128" stopColor="#cf9d34" />
        <stop offset=".195" stopColor="#cd9c32" />
        <stop offset=".196" stopColor="#cd9b32" />
        <stop offset=".237" stopColor="#be8a29" />
        <stop offset=".28" stopColor="#b68124" />
        <stop offset=".329" stopColor="#b37e23" />
        <stop offset=".348" stopColor="#b98324" />
        <stop offset=".373" stopColor="#c89026" />
        <stop offset=".388" stopColor="#d59b27" />
        <stop offset=".498" stopColor="#ddad26" />
        <stop offset=".637" stopColor="#e5c122" />
        <stop offset=".676" stopColor="#e8c743" />
        <stop offset=".761" stopColor="#efd787" />
        <stop offset=".886" stopColor="#fcf8ec" />
        <stop offset=".902" stopColor="#fff" />
      </linearGradient>
      <linearGradient
        id="SVGID_6_"
        gradientUnits="userSpaceOnUse"
        x1="28.437"
        y1="25.017"
        x2="28.437"
        y2="12.558"
      >
        <stop offset=".041" stopColor="#190e10" />
        <stop offset=".054" stopColor="#2d211c" />
        <stop offset=".083" stopColor="#513d29" />
        <stop offset=".113" stopColor="#74562f" />
        <stop offset=".144" stopColor="#966e32" />
        <stop offset=".178" stopColor="#b38332" />
        <stop offset=".214" stopColor="#ca932f" />
        <stop offset=".255" stopColor="#d89d2d" />
        <stop offset=".311" stopColor="#dea12b" />
        <stop offset=".715" stopColor="#5c100d" />
        <stop offset=".747" stopColor="#611811" />
        <stop offset=".795" stopColor="#702b19" />
        <stop offset=".855" stopColor="#894927" />
        <stop offset=".924" stopColor="#b2753a" />
        <stop offset=".999" stopColor="#f2bd58" />
        <stop offset="1" stopColor="#f3bf58" />
      </linearGradient>
      <path
        d="M32.6 22.2l-.7 2.7c-.2-.1-.5-.2-1-.2h-6.6v-.3c.5 0 .7-.1.7-.8V13.5c0-.5-.3-.6-.7-.6v-.3h3.5v.3c-.4 0-.7 0-.7.7v9.3h4c.5 0 .8-.1 1.1-.8l.4.1z"
        fill="url(#SVGID_5_)"
        stroke="url(#SVGID_6_)"
        strokeWidth=".1"
        strokeMiterlimit="10"
      />
      <linearGradient
        id="SVGID_7_"
        gradientUnits="userSpaceOnUse"
        x1="35.244"
        y1="24.774"
        x2="35.244"
        y2="12.608"
      >
        <stop offset=".056" stopColor="#fdd377" />
        <stop offset=".059" stopColor="#f6cc70" />
        <stop offset=".071" stopColor="#e6b959" />
        <stop offset=".086" stopColor="#dbab47" />
        <stop offset=".103" stopColor="#d3a23b" />
        <stop offset=".128" stopColor="#cf9d34" />
        <stop offset=".195" stopColor="#cd9c32" />
        <stop offset=".196" stopColor="#cd9b32" />
        <stop offset=".237" stopColor="#be8a29" />
        <stop offset=".28" stopColor="#b68124" />
        <stop offset=".329" stopColor="#b37e23" />
        <stop offset=".348" stopColor="#b98324" />
        <stop offset=".373" stopColor="#c89026" />
        <stop offset=".388" stopColor="#d59b27" />
        <stop offset=".498" stopColor="#ddad26" />
        <stop offset=".637" stopColor="#e5c122" />
        <stop offset=".676" stopColor="#e8c743" />
        <stop offset=".761" stopColor="#efd787" />
        <stop offset=".886" stopColor="#fcf8ec" />
        <stop offset=".902" stopColor="#fff" />
      </linearGradient>
      <linearGradient
        id="SVGID_8_"
        gradientUnits="userSpaceOnUse"
        x1="35.244"
        y1="24.824"
        x2="35.244"
        y2="12.558"
      >
        <stop offset=".041" stopColor="#190e10" />
        <stop offset=".054" stopColor="#2d211c" />
        <stop offset=".083" stopColor="#513d29" />
        <stop offset=".113" stopColor="#74562f" />
        <stop offset=".144" stopColor="#966e32" />
        <stop offset=".178" stopColor="#b38332" />
        <stop offset=".214" stopColor="#ca932f" />
        <stop offset=".255" stopColor="#d89d2d" />
        <stop offset=".311" stopColor="#dea12b" />
        <stop offset=".715" stopColor="#5c100d" />
        <stop offset=".747" stopColor="#611811" />
        <stop offset=".795" stopColor="#702b19" />
        <stop offset=".855" stopColor="#894927" />
        <stop offset=".924" stopColor="#b2753a" />
        <stop offset=".999" stopColor="#f2bd58" />
        <stop offset="1" stopColor="#f3bf58" />
      </linearGradient>
      <path
        d="M37 24.8h-3.5v-.3c.5 0 .7-.2.7-.8v-10c0-.6-.1-.8-.7-.8v-.3H37v.3c-.5 0-.6.2-.6.8v10c0 .6.1.8.6.8v.3z"
        fill="url(#SVGID_7_)"
        stroke="url(#SVGID_8_)"
        strokeWidth=".1"
        strokeMiterlimit="10"
      />
      <linearGradient
        id="SVGID_9_"
        gradientUnits="userSpaceOnUse"
        x1="43.316"
        y1="24.879"
        x2="43.316"
        y2="12.521"
      >
        <stop offset=".056" stopColor="#fdd377" />
        <stop offset=".059" stopColor="#f6cc70" />
        <stop offset=".071" stopColor="#e6b959" />
        <stop offset=".086" stopColor="#dbab47" />
        <stop offset=".103" stopColor="#d3a23b" />
        <stop offset=".128" stopColor="#cf9d34" />
        <stop offset=".195" stopColor="#cd9c32" />
        <stop offset=".196" stopColor="#cd9b32" />
        <stop offset=".237" stopColor="#be8a29" />
        <stop offset=".28" stopColor="#b68124" />
        <stop offset=".329" stopColor="#b37e23" />
        <stop offset=".348" stopColor="#b98324" />
        <stop offset=".373" stopColor="#c89026" />
        <stop offset=".388" stopColor="#d59b27" />
        <stop offset=".498" stopColor="#ddad26" />
        <stop offset=".637" stopColor="#e5c122" />
        <stop offset=".676" stopColor="#e8c743" />
        <stop offset=".761" stopColor="#efd787" />
        <stop offset=".886" stopColor="#fcf8ec" />
        <stop offset=".902" stopColor="#fff" />
      </linearGradient>
      <linearGradient
        id="SVGID_10_"
        gradientUnits="userSpaceOnUse"
        x1="43.316"
        y1="24.929"
        x2="43.316"
        y2="12.471"
      >
        <stop offset=".041" stopColor="#190e10" />
        <stop offset=".054" stopColor="#2d211c" />
        <stop offset=".083" stopColor="#513d29" />
        <stop offset=".113" stopColor="#74562f" />
        <stop offset=".144" stopColor="#966e32" />
        <stop offset=".178" stopColor="#b38332" />
        <stop offset=".214" stopColor="#ca932f" />
        <stop offset=".255" stopColor="#d89d2d" />
        <stop offset=".311" stopColor="#dea12b" />
        <stop offset=".715" stopColor="#5c100d" />
        <stop offset=".747" stopColor="#611811" />
        <stop offset=".795" stopColor="#702b19" />
        <stop offset=".855" stopColor="#894927" />
        <stop offset=".924" stopColor="#b2753a" />
        <stop offset=".999" stopColor="#f2bd58" />
        <stop offset="1" stopColor="#f3bf58" />
      </linearGradient>
      <path
        d="M41.7 24.9c-1 0-2.5 0-3.4-.1v-.3c.5 0 .7-.2.7-.7V13.6c0-.6-.2-.7-.7-.7v-.3c1-.1 2.4-.1 3.5-.1 4.7 0 6.6 2.2 6.6 6 0 3.5-1.7 6.4-6.7 6.4zm.5-10.6h-1.1V23h1.1c2.6 0 3.9-1.8 3.9-4.5 0-2.6-1.4-4.2-3.9-4.2z"
        fill="url(#SVGID_9_)"
        stroke="url(#SVGID_10_)"
        strokeWidth=".1"
        strokeMiterlimit="10"
      />
      <linearGradient
        id="SVGID_11_"
        gradientUnits="userSpaceOnUse"
        x1="53.291"
        y1="24.774"
        x2="53.291"
        y2="12.608"
      >
        <stop offset=".056" stopColor="#fdd377" />
        <stop offset=".059" stopColor="#f6cc70" />
        <stop offset=".071" stopColor="#e6b959" />
        <stop offset=".086" stopColor="#dbab47" />
        <stop offset=".103" stopColor="#d3a23b" />
        <stop offset=".128" stopColor="#cf9d34" />
        <stop offset=".195" stopColor="#cd9c32" />
        <stop offset=".196" stopColor="#cd9b32" />
        <stop offset=".237" stopColor="#be8a29" />
        <stop offset=".28" stopColor="#b68124" />
        <stop offset=".329" stopColor="#b37e23" />
        <stop offset=".348" stopColor="#b98324" />
        <stop offset=".373" stopColor="#c89026" />
        <stop offset=".388" stopColor="#d59b27" />
        <stop offset=".498" stopColor="#ddad26" />
        <stop offset=".637" stopColor="#e5c122" />
        <stop offset=".676" stopColor="#e8c743" />
        <stop offset=".761" stopColor="#efd787" />
        <stop offset=".886" stopColor="#fcf8ec" />
        <stop offset=".902" stopColor="#fff" />
      </linearGradient>
      <linearGradient
        id="SVGID_12_"
        gradientUnits="userSpaceOnUse"
        x1="53.291"
        y1="24.824"
        x2="53.291"
        y2="12.558"
      >
        <stop offset=".041" stopColor="#190e10" />
        <stop offset=".054" stopColor="#2d211c" />
        <stop offset=".083" stopColor="#513d29" />
        <stop offset=".113" stopColor="#74562f" />
        <stop offset=".144" stopColor="#966e32" />
        <stop offset=".178" stopColor="#b38332" />
        <stop offset=".214" stopColor="#ca932f" />
        <stop offset=".255" stopColor="#d89d2d" />
        <stop offset=".311" stopColor="#dea12b" />
        <stop offset=".715" stopColor="#5c100d" />
        <stop offset=".747" stopColor="#611811" />
        <stop offset=".795" stopColor="#702b19" />
        <stop offset=".855" stopColor="#894927" />
        <stop offset=".924" stopColor="#b2753a" />
        <stop offset=".999" stopColor="#f2bd58" />
        <stop offset="1" stopColor="#f3bf58" />
      </linearGradient>
      <path
        d="M58.5 24.8h-3.4v-.3c.3 0 .5-.1.5-.5 0-.1 0-.3-.1-.4l-.3-.9c-.5.1-1.2.2-2 .2s-1.5-.1-2-.2l-.3 1c-.1.2-.1.3-.1.4 0 .3.2.4.6.4v.3h-3.3v-.3c.3 0 .5-.2.7-.8l3-9.7c.1-.2.2-.5.2-.6 0-.2-.1-.3-.3-.3v-.3h3.2v.3c-.3 0-.3.3-.3.4 0 .1.1.5.2.7l3.1 9.6c.2.7.4.9.7.9v.1zm-5.3-9.3h0l-1.4 5.3c.4.1 1 .2 1.5.2s1.1-.1 1.5-.2l-1.6-5.3z"
        fill="url(#SVGID_11_)"
        stroke="url(#SVGID_12_)"
        strokeWidth=".1"
        strokeMiterlimit="10"
      />
      <linearGradient
        id="SVGID_13_"
        gradientUnits="userSpaceOnUse"
        x1="61.754"
        y1="24.774"
        x2="61.754"
        y2="12.608"
      >
        <stop offset=".056" stopColor="#fdd377" />
        <stop offset=".059" stopColor="#f6cc70" />
        <stop offset=".071" stopColor="#e6b959" />
        <stop offset=".086" stopColor="#dbab47" />
        <stop offset=".103" stopColor="#d3a23b" />
        <stop offset=".128" stopColor="#cf9d34" />
        <stop offset=".195" stopColor="#cd9c32" />
        <stop offset=".196" stopColor="#cd9b32" />
        <stop offset=".237" stopColor="#be8a29" />
        <stop offset=".28" stopColor="#b68124" />
        <stop offset=".329" stopColor="#b37e23" />
        <stop offset=".348" stopColor="#b98324" />
        <stop offset=".373" stopColor="#c89026" />
        <stop offset=".388" stopColor="#d59b27" />
        <stop offset=".498" stopColor="#ddad26" />
        <stop offset=".637" stopColor="#e5c122" />
        <stop offset=".676" stopColor="#e8c743" />
        <stop offset=".761" stopColor="#efd787" />
        <stop offset=".886" stopColor="#fcf8ec" />
        <stop offset=".902" stopColor="#fff" />
      </linearGradient>
      <linearGradient
        id="SVGID_14_"
        gradientUnits="userSpaceOnUse"
        x1="61.754"
        y1="24.824"
        x2="61.754"
        y2="12.558"
      >
        <stop offset=".041" stopColor="#190e10" />
        <stop offset=".054" stopColor="#2d211c" />
        <stop offset=".083" stopColor="#513d29" />
        <stop offset=".113" stopColor="#74562f" />
        <stop offset=".144" stopColor="#966e32" />
        <stop offset=".178" stopColor="#b38332" />
        <stop offset=".214" stopColor="#ca932f" />
        <stop offset=".255" stopColor="#d89d2d" />
        <stop offset=".311" stopColor="#dea12b" />
        <stop offset=".715" stopColor="#5c100d" />
        <stop offset=".747" stopColor="#611811" />
        <stop offset=".795" stopColor="#702b19" />
        <stop offset=".855" stopColor="#894927" />
        <stop offset=".924" stopColor="#b2753a" />
        <stop offset=".999" stopColor="#f2bd58" />
        <stop offset="1" stopColor="#f3bf58" />
      </linearGradient>
      <path
        d="M66.8 12.9c-.5 0-.6.1-.9.7l-3.1 6v4c0 .6.2.8.7.8v.3H60v-.3c.5 0 .7-.2.7-.8v-4l-3.1-6c-.3-.7-.4-.8-.9-.8v-.3h3.5v.3c-.3 0-.4.1-.4.2s.1.2.1.4l1.9 4.2h0l1.9-4.2c.1-.2.2-.4.2-.5s-.1-.2-.4-.2v-.3h3.4v.5z"
        fill="url(#SVGID_13_)"
        stroke="url(#SVGID_14_)"
        strokeWidth=".1"
        strokeMiterlimit="10"
      />
      <g>
        <linearGradient
          id="SVGID_15_"
          gradientUnits="userSpaceOnUse"
          x1="95.957"
          y1="24.601"
          x2="95.957"
          y2="12.33"
        >
          <stop offset=".056" stopColor="#fdd377" />
          <stop offset=".059" stopColor="#f6cc70" />
          <stop offset=".071" stopColor="#e6b959" />
          <stop offset=".086" stopColor="#dbab47" />
          <stop offset=".103" stopColor="#d3a23b" />
          <stop offset=".128" stopColor="#cf9d34" />
          <stop offset=".195" stopColor="#cd9c32" />
          <stop offset=".196" stopColor="#cd9b32" />
          <stop offset=".237" stopColor="#be8a29" />
          <stop offset=".28" stopColor="#b68124" />
          <stop offset=".329" stopColor="#b37e23" />
          <stop offset=".348" stopColor="#b98324" />
          <stop offset=".373" stopColor="#c89026" />
          <stop offset=".388" stopColor="#d59b27" />
          <stop offset=".498" stopColor="#ddad26" />
          <stop offset=".637" stopColor="#e5c122" />
          <stop offset=".676" stopColor="#e8c743" />
          <stop offset=".761" stopColor="#efd787" />
          <stop offset=".886" stopColor="#fcf8ec" />
          <stop offset=".902" stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="SVGID_16_"
          gradientUnits="userSpaceOnUse"
          x1="95.957"
          y1="24.651"
          x2="95.957"
          y2="12.28"
        >
          <stop offset=".041" stopColor="#190e10" />
          <stop offset=".054" stopColor="#2d211c" />
          <stop offset=".083" stopColor="#513d29" />
          <stop offset=".113" stopColor="#74562f" />
          <stop offset=".144" stopColor="#966e32" />
          <stop offset=".178" stopColor="#b38332" />
          <stop offset=".214" stopColor="#ca932f" />
          <stop offset=".255" stopColor="#d89d2d" />
          <stop offset=".311" stopColor="#dea12b" />
          <stop offset=".715" stopColor="#5c100d" />
          <stop offset=".747" stopColor="#611811" />
          <stop offset=".795" stopColor="#702b19" />
          <stop offset=".855" stopColor="#894927" />
          <stop offset=".924" stopColor="#b2753a" />
          <stop offset=".999" stopColor="#f2bd58" />
          <stop offset="1" stopColor="#f3bf58" />
        </linearGradient>
        <path
          d="M95.4 20.1h-1.2v3.5c0 .6.1.8.6.8v.3h-3.5v-.3c.5 0 .7-.2.7-.8V13.4c0-.5-.2-.7-.7-.7v-.3c1.1-.1 2.8-.1 4-.1 3.3 0 5.1 1.1 5.1 3.9.1 2.4-1.7 3.9-5 3.9zm.2-5.9c-.6 0-.9 0-1.3.1v4c.4 0 .8.1 1.3.1 1.9 0 2.8-.8 2.8-2.1-.1-1.5-1.3-2.1-2.8-2.1z"
          fill="url(#SVGID_15_)"
          stroke="url(#SVGID_16_)"
          strokeWidth=".1"
          strokeMiterlimit="10"
        />
        <linearGradient
          id="SVGID_17_"
          gradientUnits="userSpaceOnUse"
          x1="104.742"
          y1="24.601"
          x2="104.742"
          y2="12.434"
        >
          <stop offset=".056" stopColor="#fdd377" />
          <stop offset=".059" stopColor="#f6cc70" />
          <stop offset=".071" stopColor="#e6b959" />
          <stop offset=".086" stopColor="#dbab47" />
          <stop offset=".103" stopColor="#d3a23b" />
          <stop offset=".128" stopColor="#cf9d34" />
          <stop offset=".195" stopColor="#cd9c32" />
          <stop offset=".196" stopColor="#cd9b32" />
          <stop offset=".237" stopColor="#be8a29" />
          <stop offset=".28" stopColor="#b68124" />
          <stop offset=".329" stopColor="#b37e23" />
          <stop offset=".348" stopColor="#b98324" />
          <stop offset=".373" stopColor="#c89026" />
          <stop offset=".388" stopColor="#d59b27" />
          <stop offset=".498" stopColor="#ddad26" />
          <stop offset=".637" stopColor="#e5c122" />
          <stop offset=".676" stopColor="#e8c743" />
          <stop offset=".761" stopColor="#efd787" />
          <stop offset=".886" stopColor="#fcf8ec" />
          <stop offset=".902" stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="SVGID_18_"
          gradientUnits="userSpaceOnUse"
          x1="104.742"
          y1="24.651"
          x2="104.742"
          y2="12.384"
        >
          <stop offset=".041" stopColor="#190e10" />
          <stop offset=".054" stopColor="#2d211c" />
          <stop offset=".083" stopColor="#513d29" />
          <stop offset=".113" stopColor="#74562f" />
          <stop offset=".144" stopColor="#966e32" />
          <stop offset=".178" stopColor="#b38332" />
          <stop offset=".214" stopColor="#ca932f" />
          <stop offset=".255" stopColor="#d89d2d" />
          <stop offset=".311" stopColor="#dea12b" />
          <stop offset=".715" stopColor="#5c100d" />
          <stop offset=".747" stopColor="#611811" />
          <stop offset=".795" stopColor="#702b19" />
          <stop offset=".855" stopColor="#894927" />
          <stop offset=".924" stopColor="#b2753a" />
          <stop offset=".999" stopColor="#f2bd58" />
          <stop offset="1" stopColor="#f3bf58" />
        </linearGradient>
        <path
          d="M110 24.6h-3.4v-.3c.3 0 .5-.1.5-.5 0-.1 0-.3-.1-.4l-.3-.9c-.5.1-1.2.2-2 .2s-1.5-.1-2-.2l-.3 1c-.1.2-.1.3-.1.4 0 .3.2.4.6.4v.3h-3.3v-.3c.3 0 .5-.2.7-.8l3-9.7c.1-.2.2-.5.2-.6 0-.2-.1-.3-.3-.3v-.3h3.2v.3c-.3 0-.3.3-.3.4 0 .1.1.5.2.7l3.1 9.6c.2.7.4.9.7.9v.1zm-5.4-9.3h0l-1.4 5.3c.4.1 1 .2 1.5.2s1.1-.1 1.5-.2l-1.6-5.3z"
          fill="url(#SVGID_17_)"
          stroke="url(#SVGID_18_)"
          strokeWidth=".1"
          strokeMiterlimit="10"
        />
        <linearGradient
          id="SVGID_19_"
          gradientUnits="userSpaceOnUse"
          x1="114.934"
          y1="24.774"
          x2="114.934"
          y2="12.434"
        >
          <stop offset=".056" stopColor="#fdd377" />
          <stop offset=".059" stopColor="#f6cc70" />
          <stop offset=".071" stopColor="#e6b959" />
          <stop offset=".086" stopColor="#dbab47" />
          <stop offset=".103" stopColor="#d3a23b" />
          <stop offset=".128" stopColor="#cf9d34" />
          <stop offset=".195" stopColor="#cd9c32" />
          <stop offset=".196" stopColor="#cd9b32" />
          <stop offset=".237" stopColor="#be8a29" />
          <stop offset=".28" stopColor="#b68124" />
          <stop offset=".329" stopColor="#b37e23" />
          <stop offset=".348" stopColor="#b98324" />
          <stop offset=".373" stopColor="#c89026" />
          <stop offset=".388" stopColor="#d59b27" />
          <stop offset=".498" stopColor="#ddad26" />
          <stop offset=".637" stopColor="#e5c122" />
          <stop offset=".676" stopColor="#e8c743" />
          <stop offset=".761" stopColor="#efd787" />
          <stop offset=".886" stopColor="#fcf8ec" />
          <stop offset=".902" stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="SVGID_20_"
          gradientUnits="userSpaceOnUse"
          x1="114.939"
          y1="24.843"
          x2="114.939"
          y2="12.384"
        >
          <stop offset=".041" stopColor="#190e10" />
          <stop offset=".054" stopColor="#2d211c" />
          <stop offset=".083" stopColor="#513d29" />
          <stop offset=".113" stopColor="#74562f" />
          <stop offset=".144" stopColor="#966e32" />
          <stop offset=".178" stopColor="#b38332" />
          <stop offset=".214" stopColor="#ca932f" />
          <stop offset=".255" stopColor="#d89d2d" />
          <stop offset=".311" stopColor="#dea12b" />
          <stop offset=".715" stopColor="#5c100d" />
          <stop offset=".747" stopColor="#611811" />
          <stop offset=".795" stopColor="#702b19" />
          <stop offset=".855" stopColor="#894927" />
          <stop offset=".924" stopColor="#b2753a" />
          <stop offset=".999" stopColor="#f2bd58" />
          <stop offset="1" stopColor="#f3bf58" />
        </linearGradient>
        <path
          d="M119.1 22l-.7 2.7c-.2-.1-.5-.2-1-.2h-6.6v-.3c.5 0 .7-.1.7-.8v-10c0-.5-.3-.6-.7-.6v-.3h3.5v.3c-.4 0-.7 0-.7.7v9.3h4c.5 0 .8-.1 1.1-.8h.4z"
          fill="url(#SVGID_19_)"
          stroke="url(#SVGID_20_)"
          strokeWidth=".1"
          strokeMiterlimit="10"
        />
        <linearGradient
          id="SVGID_21_"
          gradientUnits="userSpaceOnUse"
          x1="124.587"
          y1="24.601"
          x2="124.587"
          y2="12.434"
        >
          <stop offset=".056" stopColor="#fdd377" />
          <stop offset=".059" stopColor="#f6cc70" />
          <stop offset=".071" stopColor="#e6b959" />
          <stop offset=".086" stopColor="#dbab47" />
          <stop offset=".103" stopColor="#d3a23b" />
          <stop offset=".128" stopColor="#cf9d34" />
          <stop offset=".195" stopColor="#cd9c32" />
          <stop offset=".196" stopColor="#cd9b32" />
          <stop offset=".237" stopColor="#be8a29" />
          <stop offset=".28" stopColor="#b68124" />
          <stop offset=".329" stopColor="#b37e23" />
          <stop offset=".348" stopColor="#b98324" />
          <stop offset=".373" stopColor="#c89026" />
          <stop offset=".388" stopColor="#d59b27" />
          <stop offset=".498" stopColor="#ddad26" />
          <stop offset=".637" stopColor="#e5c122" />
          <stop offset=".676" stopColor="#e8c743" />
          <stop offset=".761" stopColor="#efd787" />
          <stop offset=".886" stopColor="#fcf8ec" />
          <stop offset=".902" stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="SVGID_22_"
          gradientUnits="userSpaceOnUse"
          x1="124.587"
          y1="24.651"
          x2="124.587"
          y2="12.384"
        >
          <stop offset=".041" stopColor="#190e10" />
          <stop offset=".054" stopColor="#2d211c" />
          <stop offset=".083" stopColor="#513d29" />
          <stop offset=".113" stopColor="#74562f" />
          <stop offset=".144" stopColor="#966e32" />
          <stop offset=".178" stopColor="#b38332" />
          <stop offset=".214" stopColor="#ca932f" />
          <stop offset=".255" stopColor="#d89d2d" />
          <stop offset=".311" stopColor="#dea12b" />
          <stop offset=".715" stopColor="#5c100d" />
          <stop offset=".747" stopColor="#611811" />
          <stop offset=".795" stopColor="#702b19" />
          <stop offset=".855" stopColor="#894927" />
          <stop offset=".924" stopColor="#b2753a" />
          <stop offset=".999" stopColor="#f2bd58" />
          <stop offset="1" stopColor="#f3bf58" />
        </linearGradient>
        <path
          d="M129.8 24.6h-3.4v-.3c.3 0 .5-.1.5-.5 0-.1 0-.3-.1-.4l-.3-.9c-.5.1-1.2.2-2 .2s-1.5-.1-2-.2l-.3 1c-.1.2-.1.3-.1.4 0 .3.2.4.6.4v.3h-3.3v-.3c.3 0 .5-.2.7-.8l3-9.7c.1-.2.2-.5.2-.6 0-.2-.1-.3-.3-.3v-.3h3.2v.3c-.3 0-.3.3-.3.4 0 .1.1.5.2.7l3.1 9.6c.2.7.4.9.7.9v.1zm-5.4-9.3h0l-1.4 5.3c.4.1 1 .2 1.5.2s1.1-.1 1.5-.2l-1.6-5.3z"
          fill="url(#SVGID_21_)"
          stroke="url(#SVGID_22_)"
          strokeWidth=".1"
          strokeMiterlimit="10"
        />
        <linearGradient
          id="SVGID_23_"
          gradientUnits="userSpaceOnUse"
          x1="134.44"
          y1="24.774"
          x2="134.44"
          y2="12.226"
        >
          <stop offset=".056" stopColor="#fdd377" />
          <stop offset=".059" stopColor="#f6cc70" />
          <stop offset=".071" stopColor="#e6b959" />
          <stop offset=".086" stopColor="#dbab47" />
          <stop offset=".103" stopColor="#d3a23b" />
          <stop offset=".128" stopColor="#cf9d34" />
          <stop offset=".195" stopColor="#cd9c32" />
          <stop offset=".196" stopColor="#cd9b32" />
          <stop offset=".237" stopColor="#be8a29" />
          <stop offset=".28" stopColor="#b68124" />
          <stop offset=".329" stopColor="#b37e23" />
          <stop offset=".348" stopColor="#b98324" />
          <stop offset=".373" stopColor="#c89026" />
          <stop offset=".388" stopColor="#d59b27" />
          <stop offset=".498" stopColor="#ddad26" />
          <stop offset=".637" stopColor="#e5c122" />
          <stop offset=".676" stopColor="#e8c743" />
          <stop offset=".761" stopColor="#efd787" />
          <stop offset=".886" stopColor="#fcf8ec" />
          <stop offset=".902" stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="SVGID_24_"
          gradientUnits="userSpaceOnUse"
          x1="134.443"
          y1="24.824"
          x2="134.443"
          y2="12.176"
        >
          <stop offset=".041" stopColor="#190e10" />
          <stop offset=".054" stopColor="#2d211c" />
          <stop offset=".083" stopColor="#513d29" />
          <stop offset=".113" stopColor="#74562f" />
          <stop offset=".144" stopColor="#966e32" />
          <stop offset=".178" stopColor="#b38332" />
          <stop offset=".214" stopColor="#ca932f" />
          <stop offset=".255" stopColor="#d89d2d" />
          <stop offset=".311" stopColor="#dea12b" />
          <stop offset=".715" stopColor="#5c100d" />
          <stop offset=".747" stopColor="#611811" />
          <stop offset=".795" stopColor="#702b19" />
          <stop offset=".855" stopColor="#894927" />
          <stop offset=".924" stopColor="#b2753a" />
          <stop offset=".999" stopColor="#f2bd58" />
          <stop offset="1" stopColor="#f3bf58" />
        </linearGradient>
        <path
          d="M138.9 21.5l-.5 3.1s-.3-.1-.6-.1c-.5 0-1.4.3-2.1.3-3.5 0-5.6-2.4-5.6-6.3 0-3.8 2.2-6.3 5.6-6.3.7 0 1.7.2 2.1.2.3 0 .6-.1.6-.1l.5 2.9-.4.1c-.3-.8-1.2-1.2-2.8-1.2-2.2 0-3.5 1.7-3.5 4.4 0 2.9 1.5 4.4 3.5 4.4 1.6 0 2.4-.6 2.8-1.5l.4.1z"
          fill="url(#SVGID_23_)"
          stroke="url(#SVGID_24_)"
          strokeWidth=".1"
          strokeMiterlimit="10"
        />
        <linearGradient
          id="SVGID_25_"
          gradientUnits="userSpaceOnUse"
          x1="144.432"
          y1="24.723"
          x2="144.432"
          y2="12.313"
        >
          <stop offset=".056" stopColor="#fdd377" />
          <stop offset=".059" stopColor="#f6cc70" />
          <stop offset=".071" stopColor="#e6b959" />
          <stop offset=".086" stopColor="#dbab47" />
          <stop offset=".103" stopColor="#d3a23b" />
          <stop offset=".128" stopColor="#cf9d34" />
          <stop offset=".195" stopColor="#cd9c32" />
          <stop offset=".196" stopColor="#cd9b32" />
          <stop offset=".237" stopColor="#be8a29" />
          <stop offset=".28" stopColor="#b68124" />
          <stop offset=".329" stopColor="#b37e23" />
          <stop offset=".348" stopColor="#b98324" />
          <stop offset=".373" stopColor="#c89026" />
          <stop offset=".388" stopColor="#d59b27" />
          <stop offset=".498" stopColor="#ddad26" />
          <stop offset=".637" stopColor="#e5c122" />
          <stop offset=".676" stopColor="#e8c743" />
          <stop offset=".761" stopColor="#efd787" />
          <stop offset=".886" stopColor="#fcf8ec" />
          <stop offset=".902" stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="SVGID_26_"
          gradientUnits="userSpaceOnUse"
          x1="144.437"
          y1="24.792"
          x2="144.437"
          y2="12.244"
        >
          <stop offset=".041" stopColor="#190e10" />
          <stop offset=".054" stopColor="#2d211c" />
          <stop offset=".083" stopColor="#513d29" />
          <stop offset=".113" stopColor="#74562f" />
          <stop offset=".144" stopColor="#966e32" />
          <stop offset=".178" stopColor="#b38332" />
          <stop offset=".214" stopColor="#ca932f" />
          <stop offset=".255" stopColor="#d89d2d" />
          <stop offset=".311" stopColor="#dea12b" />
          <stop offset=".715" stopColor="#5c100d" />
          <stop offset=".747" stopColor="#611811" />
          <stop offset=".795" stopColor="#702b19" />
          <stop offset=".855" stopColor="#894927" />
          <stop offset=".924" stopColor="#b2753a" />
          <stop offset=".999" stopColor="#f2bd58" />
          <stop offset="1" stopColor="#f3bf58" />
        </linearGradient>
        <path
          d="M148.7 22.1l-.6 2.6c-.2-.1-.6-.1-.9-.1h-7.1v-.3c.5 0 .7-.2.7-.8v-10c0-.6-.1-.8-.7-.8v-.3h7.1c.3 0 .6-.1.8-.1l.6 2.6-.4.1c-.2-.4-.4-.7-1.1-.7H143V17h4c.5 0 .7-.1.7-.6h.3v3h-.3c0-.5-.2-.6-.7-.6h-4v4h4.4c.6 0 .8-.3 1-.7h.3z"
          fill="url(#SVGID_25_)"
          stroke="url(#SVGID_26_)"
          strokeWidth=".1"
          strokeMiterlimit="10"
        />
      </g>
      <g>
        <linearGradient
          id="SVGID_27_"
          gradientUnits="userSpaceOnUse"
          x1="76.63"
          y1="26.968"
          x2="76.63"
          y2="15.959"
        >
          <stop offset=".056" stopColor="#fdd377" />
          <stop offset=".059" stopColor="#f6cc70" />
          <stop offset=".071" stopColor="#e6b959" />
          <stop offset=".086" stopColor="#dbab47" />
          <stop offset=".103" stopColor="#d3a23b" />
          <stop offset=".128" stopColor="#cf9d34" />
          <stop offset=".195" stopColor="#cd9c32" />
          <stop offset=".196" stopColor="#cd9b32" />
          <stop offset=".237" stopColor="#be8a29" />
          <stop offset=".28" stopColor="#b68124" />
          <stop offset=".329" stopColor="#b37e23" />
          <stop offset=".348" stopColor="#b98324" />
          <stop offset=".373" stopColor="#c89026" />
          <stop offset=".388" stopColor="#d59b27" />
          <stop offset=".498" stopColor="#ddad26" />
          <stop offset=".637" stopColor="#e5c122" />
          <stop offset=".676" stopColor="#e8c743" />
          <stop offset=".761" stopColor="#efd787" />
          <stop offset=".886" stopColor="#fcf8ec" />
          <stop offset=".902" stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="SVGID_28_"
          gradientUnits="userSpaceOnUse"
          x1="76.63"
          y1="27.083"
          x2="76.63"
          y2="15.883"
        >
          <stop offset=".041" stopColor="#190e10" />
          <stop offset=".054" stopColor="#2d211c" />
          <stop offset=".083" stopColor="#513d29" />
          <stop offset=".113" stopColor="#74562f" />
          <stop offset=".144" stopColor="#966e32" />
          <stop offset=".178" stopColor="#b38332" />
          <stop offset=".214" stopColor="#ca932f" />
          <stop offset=".255" stopColor="#d89d2d" />
          <stop offset=".311" stopColor="#dea12b" />
          <stop offset=".715" stopColor="#5c100d" />
          <stop offset=".747" stopColor="#611811" />
          <stop offset=".795" stopColor="#702b19" />
          <stop offset=".855" stopColor="#894927" />
          <stop offset=".924" stopColor="#b2753a" />
          <stop offset=".999" stopColor="#f2bd58" />
          <stop offset="1" stopColor="#f3bf58" />
        </linearGradient>
        <path
          d="M79.2 27l-2.1-1v-.4c.3 0 .4-.3.4-.7v-4.1h-1.7v3.7c0 .3.1.8.4.8v.4l-2.2-1v-.3c.2 0 .4-.2.4-.5v-7.2c0-.4-.1-.5-.4-.5V16h2.1v.2c-.3 0-.4.2-.4.5v3.1h1.7v-3.1c0-.4-.1-.5-.4-.5V16h2.1v.2c-.3 0-.4.2-.4.5V25c0 .3.1 1.6.4 1.6v.4z"
          fill="url(#SVGID_27_)"
          stroke="url(#SVGID_28_)"
          strokeWidth=".15"
          strokeMiterlimit="10"
        />
        <linearGradient
          id="SVGID_29_"
          gradientUnits="userSpaceOnUse"
          x1="82.082"
          y1="26.968"
          x2="82.082"
          y2="15.874"
        >
          <stop offset=".056" stopColor="#fdd377" />
          <stop offset=".059" stopColor="#f6cc70" />
          <stop offset=".071" stopColor="#e6b959" />
          <stop offset=".086" stopColor="#dbab47" />
          <stop offset=".103" stopColor="#d3a23b" />
          <stop offset=".128" stopColor="#cf9d34" />
          <stop offset=".195" stopColor="#cd9c32" />
          <stop offset=".196" stopColor="#cd9b32" />
          <stop offset=".237" stopColor="#be8a29" />
          <stop offset=".28" stopColor="#b68124" />
          <stop offset=".329" stopColor="#b37e23" />
          <stop offset=".348" stopColor="#b98324" />
          <stop offset=".373" stopColor="#c89026" />
          <stop offset=".388" stopColor="#d59b27" />
          <stop offset=".498" stopColor="#ddad26" />
          <stop offset=".637" stopColor="#e5c122" />
          <stop offset=".676" stopColor="#e8c743" />
          <stop offset=".761" stopColor="#efd787" />
          <stop offset=".886" stopColor="#fcf8ec" />
          <stop offset=".902" stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="SVGID_30_"
          gradientUnits="userSpaceOnUse"
          x1="82.082"
          y1="27.095"
          x2="82.082"
          y2="15.8"
        >
          <stop offset=".041" stopColor="#190e10" />
          <stop offset=".054" stopColor="#2d211c" />
          <stop offset=".083" stopColor="#513d29" />
          <stop offset=".113" stopColor="#74562f" />
          <stop offset=".144" stopColor="#966e32" />
          <stop offset=".178" stopColor="#b38332" />
          <stop offset=".214" stopColor="#ca932f" />
          <stop offset=".255" stopColor="#d89d2d" />
          <stop offset=".311" stopColor="#dea12b" />
          <stop offset=".715" stopColor="#5c100d" />
          <stop offset=".747" stopColor="#611811" />
          <stop offset=".795" stopColor="#702b19" />
          <stop offset=".855" stopColor="#894927" />
          <stop offset=".924" stopColor="#b2753a" />
          <stop offset=".999" stopColor="#f2bd58" />
          <stop offset="1" stopColor="#f3bf58" />
        </linearGradient>
        <path
          d="M82 22.5h-.4V25c0 .3.2.5.5.5v.2L79.8 27v-.4c.3 0 .4-1.2.4-1.6v-8.3c0-.4-.1-.6-.4-.6V16c.5 0 1.1-.1 1.9-.1 1.7 0 2.5 1.1 2.5 3.4.1 2.1-.9 3.2-2.2 3.2zm-.1-5.5h-.3v4.4h.3c.5 0 1-.6 1-2.2 0-1.5-.4-2.2-1-2.2z"
          fill="url(#SVGID_29_)"
          stroke="url(#SVGID_30_)"
          strokeWidth=".15"
          strokeMiterlimit="10"
        />
      </g>
      <path fill="none" d="M60.6 24.1h59.2v10H60.6z" />
      <path fill="none" d="M85.1 24.8h11.7v9.6H85.1z" />
      <linearGradient
        id="SVGID_31_"
        gradientUnits="userSpaceOnUse"
        x1="78.978"
        y1="30.314"
        x2="78.978"
        y2="9.684"
      >
        <stop offset=".056" stopColor="#fdd377" />
        <stop offset=".059" stopColor="#f6cc70" />
        <stop offset=".071" stopColor="#e6b959" />
        <stop offset=".086" stopColor="#dbab47" />
        <stop offset=".103" stopColor="#d3a23b" />
        <stop offset=".128" stopColor="#cf9d34" />
        <stop offset=".195" stopColor="#cd9c32" />
        <stop offset=".196" stopColor="#cd9b32" />
        <stop offset=".237" stopColor="#be8a29" />
        <stop offset=".28" stopColor="#b68124" />
        <stop offset=".329" stopColor="#b37e23" />
        <stop offset=".348" stopColor="#b98324" />
        <stop offset=".373" stopColor="#c89026" />
        <stop offset=".388" stopColor="#d59b27" />
        <stop offset=".498" stopColor="#ddad26" />
        <stop offset=".637" stopColor="#e5c122" />
        <stop offset=".676" stopColor="#e8c743" />
        <stop offset=".761" stopColor="#efd787" />
        <stop offset=".886" stopColor="#fcf8ec" />
        <stop offset=".902" stopColor="#fff" />
      </linearGradient>
      <linearGradient
        id="SVGID_32_"
        gradientUnits="userSpaceOnUse"
        x1="78.978"
        y1="30.389"
        x2="78.978"
        y2="9.618"
      >
        <stop offset=".041" stopColor="#190e10" />
        <stop offset=".054" stopColor="#2d211c" />
        <stop offset=".083" stopColor="#513d29" />
        <stop offset=".113" stopColor="#74562f" />
        <stop offset=".144" stopColor="#966e32" />
        <stop offset=".178" stopColor="#b38332" />
        <stop offset=".214" stopColor="#ca932f" />
        <stop offset=".255" stopColor="#d89d2d" />
        <stop offset=".311" stopColor="#dea12b" />
        <stop offset=".715" stopColor="#5c100d" />
        <stop offset=".747" stopColor="#611811" />
        <stop offset=".795" stopColor="#702b19" />
        <stop offset=".855" stopColor="#894927" />
        <stop offset=".924" stopColor="#b2753a" />
        <stop offset=".999" stopColor="#f2bd58" />
        <stop offset="1" stopColor="#f3bf58" />
      </linearGradient>
      <path
        d="M84.3 10v1.8s-2.2-.3-3.6-.3V9.8s-1.2-.2-1.8-.1c0 0-1.3 0-1.8.1v1.7s-2.5.1-3.6.3V10s-1.1.1-1.9.4v15.7l7.2 4.2 7.2-4.2V10.4c.2 0-.9-.4-1.7-.4zm.9 15.3L79 28.8l-6.2-3.5V14.8s6.3-.8 12.4 0v10.5z"
        fill="url(#SVGID_31_)"
        stroke="url(#SVGID_32_)"
        strokeWidth=".13"
        strokeMiterlimit="10"
      />
    </svg>
  )
}

export default Logo
