import React, { useContext } from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { breakpoints, color } from "../../utils/style"
import ButtonLink from "../shared/ButtonLink"
import { LoginFormOpen, LogoutButton } from "../../components/Auth/Auth"
import StoreContext from "../shared/Context/StoreContext"

const HeaderContacts = styled("div")`
  flex-basis: 70%;
  text-align: right;
  padding-top: 7px;

  .header-contact-group {
    display: flex;
    .button {
      padding: 9px 20px 5px;
      margin-left: 10px;
      font-size: 14px;
      text-align: left;
      display: flex;
      align-items: center;
    }
    .green-button {
      width: 205px;
      height: 46px;
      margin: 0;
      span {
        font-size: 14px;
        font-weight: bold;
        display: inline-block;
        vertical-align: baseline;
      }
    }
    .register-button {
      padding: 0;
      height: 48px;
      width: 171px;
      text-align: center;
      span {
        display: inline-block;
        vertical-align: middle;
        margin-top: 2px;
        line-height: 22px;
      }
      &:hover,
      &:active {
        padding: 10px 23px 6px;
      }
    }
    .logout-button {
      height: auto;
      padding: 14px 23px;
      width: 178px;
      text-align: center;
      svg {
        margin-left: 7px;
        vertical-align: top !important;
      }
    }
  }
  .button {
    display: inline-block;
    vertical-align: top;
    margin-bottom: 20px;
  }
  .gatsby-image-wrapper {
    border-radius: 100%;
  }
  .popup-content {
    text-align: center;
  }

  @media (max-width: ${breakpoints.lg}) {
    flex-basis: 76%;
    .header-contact-group {
      .button {
        margin: 0 0 0 10px;
      }
    }
  }

  @media (max-width: ${breakpoints.md}) {
    display: none;
    .header-contact-group {
      display: none;
    }
  }
`

export default ({ children }) => {
  const value = useContext(StoreContext)
  const username = value.username
  return (
    <HeaderContacts id="header-contacts-menu" className="header-contacts-menu">
      <div className="header-contact-group">
        <ButtonLink
          to={process.env.LINE_ID}
          text={process.env.LINE_ID_TEXT}
          external={true}
          className="button gold-button"
          iconName="line"
          iconSize={24}
          iconColor={color.white}
          ariaText={process.env.LINE_ID_TEXT}
          iconLeft={true}
        />

        <ButtonLink
          to={`tel:${process.env.PHONE_TO_CALL}`}
          external={true}
          ariaText="Call"
          className="button gold-button phone-cell-button"
          iconColor={color.white}
          text={process.env.PHONE_TO_DISPLAY}
          iconName="call-answer"
          iconLeft={true}
          iconSize={20}
        />

        {children}

        {username ? (
          <LogoutButton />
        ) : (
          <button
            className="button transparent-button register-button"
            onClick={e => LoginFormOpen(e, "login-popup-window")}
          >
            <span>เข้าสู่ระบบ</span>
          </button>
        )}

        <Link
          className="button transparent-button"
          to="/register/"
          text="Apply"
        >
          <span>สมัครสมาชิก</span>
        </Link>
      </div>
    </HeaderContacts>
  )
}
