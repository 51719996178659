import React from 'react';

const Horseshoe = ({ width="20", height="21", viewBox="0 0 20 21" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none" xmlns="http://www.w3.org/2000/svg"
    >
      <path fillRule="evenodd" clipRule="evenodd"
            d="M10 21c8.065 0 10-5.827 10-9.095 0-3.628-1.097-5.71-2.07-7.557-.258-.49-.507-.964-.725-1.445l1.374-.74V0h-5.066s-.002 1.955 0 1.955c0 1.003.39 1.987.826 3.091.512 1.298 1.091 2.762 1.182 4.62.249 5.11-3.313 6.288-5.521 6.288-2.209 0-5.771-1.178-5.522-6.288.091-1.858.67-3.322 1.182-4.62.437-1.104.825-2.088.825-3.091.002 0 0-1.955 0-1.955H1.42v2.164l1.374.739c-.218.48-.467.954-.725 1.444-.972 1.846-2.07 3.928-2.07 7.558C0 15.173 1.934 21 10 21zm.502-2.853a.493.493 0 01.521.442l.002.02a.486.486 0 01-.455.532c-.366.016-.741.017-1.104.002a.485.485 0 01-.456-.53l.002-.026a.487.487 0 01.513-.438c.32.013.653.012.977-.002zm5.043-1.504l-.014-.014a.486.486 0 00-.656-.027 5.725 5.725 0 01-.784.566.482.482 0 00-.176.631l.009.018c.13.257.453.343.7.194.317-.191.614-.407.892-.645a.49.49 0 00.03-.723zm1.577-2.543l-.029-.01a.481.481 0 01-.294-.597 8.76 8.76 0 00.218-.938.494.494 0 01.545-.412l.02.002a.486.486 0 01.425.557c-.065.373-.15.737-.254 1.09a.487.487 0 01-.631.308zm-.317-6.34a.489.489 0 01.339-.59l.023-.006a.487.487 0 01.607.346c.086.351.155.708.212 1.074a.486.486 0 01-.43.55l-.02.003a.492.492 0 01-.542-.42c-.05-.326-.113-.644-.189-.957zM5.937 17.185a5.672 5.672 0 01-.788-.561.486.486 0 00-.656.03l-.014.015a.49.49 0 00.033.723c.28.236.579.45.896.638.248.149.57.06.7-.197l.008-.018a.481.481 0 00-.18-.63zm-2.95-4.597c.058.32.132.634.223.937a.483.483 0 01-.292.598l-.028.01a.486.486 0 01-.633-.305 9.667 9.667 0 01-.26-1.09.485.485 0 01.425-.558l.02-.002a.493.493 0 01.545.41zm-.143-5.382L2.823 7.2a.488.488 0 00-.607.348c-.085.351-.153.707-.209 1.074-.04.27.159.518.432.55l.02.001a.493.493 0 00.54-.42 11.7 11.7 0 01.186-.96.488.488 0 00-.34-.587z"
            fill="#F5C109"/>
    </svg>
  )
};

export default Horseshoe;